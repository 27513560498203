import { Button, Card, CardActions, CardContent, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import { ChangePasswordDTO } from "../../models/UserModel";
import useForm from "../../utility/hooks/UseForm";
import { AuthContext } from "../../utility/context/AuthContext";
import { userService } from '../../services/UserService';
import Controls from '../../utility/Controls.tsx/Controls';

const ResetPasswordForm = () => {
    const { userId } = useParams();
    const { auth, setAuth } = React.useContext(AuthContext);
    let navigate = useNavigate();
    const prevPgState = useLocation();

    useEffect(() => {
        newChangePassword();
        setErrors({})
    }, [userId]);


    const validate = (fieldValues: ChangePasswordDTO = values) => {
        let temp: any = { ...errors };
        if ("OldPassword" in fieldValues)
            temp.OldPassword = fieldValues.OldPassword ? "" : "Old Password is required.";

        if ("NewPassword" in fieldValues)
            temp.NewPassword = fieldValues.NewPassword ? "" : "New Password is required.";

        if ("ConfirmPassword" in fieldValues)
            temp.ConfirmPassword = fieldValues.ConfirmPassword ? "" : "Confirm Password is required.";

        // if (("NewPassword" in fieldValues) || ("ConfirmPassword" in fieldValues)) {
        //     if (fieldValues.ConfirmPassword)
        //         temp.ConfirmPassword = (values.NewPassword !== fieldValues.ConfirmPassword) ? "New Password and ConfirmPassword doesn't match." : "";
        //     else
        //         temp.NewPassword = (values.ConfirmPassword !== fieldValues.NewPassword) ? "New Password and ConfirmPassword doesn't match." : "";
        //}

        // Check if NewPassword and ConfirmPassword match
        // Check if NewPassword and ConfirmPassword match
        if (fieldValues.NewPassword || fieldValues.ConfirmPassword) {
            
            const newPassword = fieldValues.NewPassword || values.NewPassword || "";
            const confirmPassword = fieldValues.ConfirmPassword || values.ConfirmPassword || "";

            const passwordsMatch = newPassword === confirmPassword;

            temp.NewPassword = passwordsMatch ? "" : "Passwords do not match.";
            temp.ConfirmPassword = passwordsMatch ? "" : "Passwords do not match.";
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(userService.initialChangePasswordFieldValues, validate, userId);

    const newChangePassword = () => {
        setValues(userService.initialChangePasswordFieldValues);
    };

    function setFormValue(model: ChangePasswordDTO) {
        let newModel = {
            UserId: model.UserId,
            NewPassword: model.NewPassword || "",
            ConfirmPassword: model.ConfirmPassword || "",
            OldPassword: model.OldPassword || "",
        };
        return newModel;
    }

    // const getUser = (UserId: any) => {
    //     
    //     userService.getById(UserId).then((response) => {
    //         if (response) {
    //             let result = response.data;
    //             setValues(setFormValue(result.row));
    //         }
    //     });
    // };


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validate()) {
            if (userId) {
                userService.ChangePassword(values).then((response: any) => {
                    let result = response.data;
                    if (response) {
                        globalService.success(result.message);
                        resetForm();
                        goToLogin();
                    } else {
                        globalService.error(result.message);
                    }
                });
            }
        }
    };

    function goToLogin() {
        localStorage.clear();
        setAuth(null);
        navigate("/login");
    }

    return (
        <div>
            <Typography variant="h5" align="center">
                Change Password
            </Typography>
            <>
                <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Card>
                        <CardContent>
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Controls.Input
                                            name="OldPassword"
                                            required
                                            label="Old Password"
                                            type="password"
                                            value={values.OldPassword}
                                            onChange={handleInputChange}
                                            error={errors.OldPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controls.Input
                                            name="NewPassword"
                                            required
                                            label="New Password"
                                            type="password"
                                            value={values.NewPassword}
                                            onChange={handleInputChange}
                                            error={errors.NewPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controls.Input
                                            name="ConfirmPassword"
                                            required
                                            label="Confirm Password"
                                            type="password"
                                            value={values.ConfirmPassword}
                                            onChange={handleInputChange}
                                            error={errors.ConfirmPassword}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        </CardContent>
                        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                            <Stack spacing={2} direction="row">
                                <Button type="submit" variant="contained">
                                    Submit
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => navigate("/users/")}
                                >
                                    Back To List
                                </Button>
                            </Stack>
                        </CardActions>
                    </Card>
                </form>
            </>
        </div>
    )
}

export default ResetPasswordForm