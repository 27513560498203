import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  Tooltip,
  Link,
  CardHeader,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import React, { useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { ExpenseHdrsService } from "../../services/ExpenseHdrsService";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { MultiSelectListModel, SelectListModel } from "../../models/ApiResponse";
import { mEmployeesService } from "../../services/MEmployeeService";
import { Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import { globalService } from "../../services/GlobalService";
import ConfirmDialog from "../helper/ConfirmDialog";
import { GetExpensesModel } from "../../models/ExpenseHdrsModel";
import { ROLES, config } from "../../utility/Config";
import { AuthContext } from "../../utility/context/AuthContext";
import { WidthFull } from "@mui/icons-material";
import Draggable from "react-draggable";
import ExpenseQueriesList from "./ExpenseQueriesList";
import { expenseDtlFileService } from "../../services/ExpenseDtlFileService";
import { mSiteService } from "../../services/MSiteService";
import { projectDirectoryService } from "../../services/ProjectDirectoryService";
import { ProjectDirectoryModel } from "../../models/ProjectDirectoryModel";
import ExpenseDtlsList from "./ExpenseDtlsList";

const ExpenseHdrsList = (...props: any) => {
  const [expenseHdrs, setExpenseHdrs] = useState([]);
  const [expenseHdrsSelectList, setExpenseHdrsSelectList] = useState<
    SelectListModel[]
  >([]);
  const [sites, setSites] = useState<SelectListModel[]>([]);
  const [projectDirectory, setProjectDirectory] = useState<ProjectDirectoryModel>(null);
  //const [employees, setEmployees] = useState([]);
  //const { UserId }: any = useParams();
  const params = useParams();
  const [expenseDtlFiles, setExpenseDtlFiles] = useState([]);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const { auth } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [visible, SetVisible] = useState<boolean>(false);
  const [expenseHdrId, setExpenseHdrId] = useState<any>(null);
  const [expenseQueryOpen, setExpenseQueryOpen] = React.useState(false);
  const [expenseDtlsOpen, setExpenseDtlsOpen] = React.useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [selectedExpenseHdrsIds, setSelectedExpenseHdrsIds] = useState<
    string[]
  >([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });
  const [expenseDtlDocOpen, setExpenseDtlDocOpen] = React.useState(false);

  const openExpenseDtlDocDialog = (expenseHdrId: any) => {
    setExpenseDtlDocOpen(true);
    getExpenseDtlFilesByHeaderId(expenseHdrId);
  };

  const closeExpenseDtlDocDialog = () => {
    setExpenseDtlDocOpen(false);
  };

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      ExpenseHdrsService.initialFieldValues,
      validate,
      props.setCurrentId
    );

  useEffect(() => {
    if (sites.length === 0) getSites();
    //getEmployees();
    if (expenseHdrs.length == 0) {
      setValues((prevValues: any) => ({
        ...prevValues,
        FromDt: get3MonthsAgoDate(),
        ToDt: new Date(),
      }));
      handleGo();
    }
    // if (expenseHdrsSelectList.length === 0) getZoneApprovals();
    // getZoneApprovals();
    // getExpenseHdrs();
  }, []);

  // Update the total whenever filtered rows change
  useEffect(() => {
    calculateTotalAmount(expenseHdrs);
  }, [expenseHdrs]);

  const initialColumnVisibility: any = {
    ExpenseHdrId: false,
    ApproverName: true,
    //ExpenseHdrStatus: globalService.roleMatch([ROLES.Accounts], auth) ? false : true,
    Actions: globalService.roleMatch([ROLES.Accounts], auth) ? false : true,
    Level1ApprovalStatusDate: globalService.roleMatch([ROLES.Accounts], auth) ? false : true,
    CreatedById: globalService.roleMatch([ROLES.Accounts], auth) ? false : true,
    //Level2ApprovedByUser: globalService.roleMatch([ROLES.Accounts], auth) ? false : true,
    Level2ApprovalStatusDate: globalService.roleMatch([ROLES.Accounts], auth) ? false : true,
    SendFor: globalService.roleMatch([ROLES.Accounts], auth) ? false : true,
    //ExpenseDescription: globalService.roleMatch([ROLES.Accounts], auth)? ((width:300): (width: 200))
  };

  //show/hide columns dynamically
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialColumnVisibility
  );

  // Update column visibility dynamically when ExpenseHdrStatusID changes
  useEffect(() => {
    console.log('In SetVisibility : ' + values.ExpenseHdrStatusID);
    setColumnVisibilityModel((prev: any) => ({
      ...prev,
      ApproverName: values.ExpenseHdrStatusID === '1',
    }));
  }, [values.ExpenseHdrStatusID]);


  const handleApprovalInputOpen = (row: any) => {
    setOpen(true);
  };

  const handleRateInputClose = () => {
    setOpen(false);
  };

  const openExpenseQueryDialog = (expenseHdrId: any) => {
    setExpenseHdrId(expenseHdrId);
    setExpenseQueryOpen(true);
  };
  const closeExpenseQueryDialog = () => {
    setExpenseQueryOpen(false);
  };
  const openExpenseDtlsDialog = (expenseHdrId: any) => {
    setExpenseHdrId(expenseHdrId);
    setExpenseDtlsOpen(true);
  };
  const closeExpenseDtlsDialog = () => {
    setExpenseDtlsOpen(false);
    getExpenseHdrs(values.ExpenseHdrStatusID);
  };

  const SendingForApproval = (ExpenseHdrId: any, CreatedById: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    const updatedValues = {
      ...values,
      ExpenseHdrId,
      ExpenseHdrStatusID: 1,
      CreatedById,
      // Level1ApprovedByUserId: values.ApproverUserID,
      UpdatedById: auth.Id,
    };

    values.ExpenseHdrId = ExpenseHdrId;

    ExpenseHdrsService.UpdateExpenseHdrStatusID(updatedValues).then(
      (response: any) => {
        let result = response.data;
        if (response) {
          globalService.success(result.message);
          // getZoneApprovals(auth.Id);
          getExpenseHdrs(values.ExpenseHdrStatusId);
          navigate("/expenses");
        } else {
          globalService.error(result.message);
        }
      }
    );
  };

  function setFormValue(model: GetExpensesModel) {
    let newValue = {
      ProjectDirectoryId: model.ProjectDirectoryId,
      UserID: model.UserID,
      ExpenseHdrStatusID: model.ExpenseHdrStatusID || "",
      FromDt: model.FromDt
        ? new Date(model.FromDt)
        : globalService.convertLocalToUTCDate(new Date()),
      ToDt: model.ToDt
        ? new Date(model.ToDt)
        : globalService.convertLocalToUTCDate(new Date()),
    };
    return newValue;
  }

  const handleGo = () => {
    if (validate()) {
      getExpenseHdrs("0");
    }
  };

  function get3MonthsAgoDate() {
    const currentDate = new Date();
    const ThreeMonthsAgo = new Date(currentDate);
    ThreeMonthsAgo.setMonth(currentDate.getMonth() - 3);
    return ThreeMonthsAgo;
  }

  const refresh = () => {
    setValues((prevValues: any) => ({
      ...prevValues,
      FromDt: get3MonthsAgoDate(),
      ToDt: new Date(),
      UserID: auth.Id,
      SiteId: values.SiteId,
      ProjectDirectoryId: ''
    }));
    // localStorage.removeItem("FromDt");
    // localStorage.removeItem("ToDt");
  };

  // const getExpenseHdrs = () => {
  //   setValues((prevValues: any) => ({
  //     ...prevValues,
  //     FromDt: new Date(localStorage.getItem('FromDt')) || get3MonthsAgoDate(),
  //     ToDt: new Date(localStorage.getItem('ToDt')) || new Date(),
  //     UserID: auth.Id
  //   }));

  //   var model = setFormValue(values);
  //   //values.FromDt = values.FromDt || get3MonthsAgoDate();
  //   //values.ToDt = values.ToDt  || new Date();

  //   ExpenseHdrsService.getAll(model).then((response) => {
  //     let result = response.data;

  //     if (result.isSuccess) {
  //       setExpenseHdrs(result.list);
  //       SetVisible(true);
  //     } else {
  //       globalService.error(result.message);
  //     }
  //   });
  // };



  const getExpenseHdrs = (expenseHdrStatusId: any) => {

    setValues((prevValues: any) => ({
      ...prevValues,
      // FromDt: get3MonthsAgoDate(),
      // ToDt: new Date(),
      UserID: auth.Id,
      ExpenseHdrStatusID: expenseHdrStatusId
    }));

    //values.ExpenseHdrStatusID = expenseHdrStatusId;
    const updatedValues = {
      ...values, // values here won't reflect the update, so you need to use a new object if you're re-calling it.
      ExpenseHdrStatusID: expenseHdrStatusId,
    };

    var model = setFormValue(updatedValues);

    if (globalService.roleMatch([ROLES.Accounts], auth)) {
      // const updatedValues = {
      //   ...values,
      //   ExpenseHdrStatusID: 2,
      // };
      console.log("ExpenseHdrStatusID : " + updatedValues.ExpenseHdrStatusID);
      ExpenseHdrsService.getAll(updatedValues).then((response) => {
        let result = response.data;
        if (result.isSuccess) {

          setExpenseHdrs(result.list);
          SetVisible(true);
        } else {
          globalService.error(result.message);
        }
      });
    } else {
      ExpenseHdrsService.getAll(model).then((response) => {
        let result = response.data;
        if (result.isSuccess) {

          setExpenseHdrs(result.list);
          SetVisible(true);
        } else {
          globalService.error(result.message);
        }
      });
    }
  };

  // const getEmployees = () => {
  //   mEmployeesService.getAll().then((response) => {
  //     let result = response.data;
  //     setEmployees(result.list);
  //   });
  // };

  const getZoneApprovals = () => {
    ExpenseHdrsService.getZoneApprovers(auth.Id).then((response) => {
      //let result = response.data;
      setExpenseHdrsSelectList(response.data);
    });
  };

  const getSites = () => {
    mSiteService.GetAllMultiSelectList().then((response: any) => {
      setSites(response.data);
    });
  };

  const getProjectDirectory = (siteId: any) => {
    if (siteId) {
      projectDirectoryService
        .GetBySiteId(siteId)
        .then((response) => {
          let result = response.data;
          setProjectDirectory(result.row);
          if (result.row)
            setValues((prevValues: any) => ({
              ...prevValues,
              ProjectDirectoryId: result.row.ProjectDirectoryId,
            }));
        });
    }
  };


  const UpdateForTally = (ExpenseHdrIds: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const formattedExpenseHdrIds = Array.isArray(ExpenseHdrIds)
      ? ExpenseHdrIds
      : ExpenseHdrIds.split(",").map((id: string) => id.trim());
    const updatedValues = {
      ...values,
      ExpenseHdrIds: formattedExpenseHdrIds,
      ExpenseHdrStatusID: 6,
    };

    ExpenseHdrsService.statusUpdateForTallyTransfer(updatedValues).then(
      (response: any) => {
        let result = response.data;

        if (response) {
          globalService.success(result.message);
          getExpenseHdrs(values.ExpenseHdrStatusId);
          navigate("/expenses");
          setSelectedExpenseHdrsIds([]);
        } else {
          globalService.error(result.message);
        }
      }
    );
  };
  // }

  const handleConvertedmarkfortallySelectionChange = (selectedIds: any) => {
    setSelectedExpenseHdrsIds(selectedIds);
  };

  const getExpenseDtlFilesByHeaderId = (ExpenseHeaderId: any) => {
    expenseDtlFileService
      .getAllFileByExpenseHeaderId(ExpenseHeaderId)
      .then((response) => {
        let result = response.data;
        setExpenseDtlFiles(result.list);
      });
  };

  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(
      `${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`,
      "_blank"
    );
  };

  const expenseDtlFileColumns: GridColDef[] = [
    {
      field: "ExpenseHeadName",
      headerName: "Expense Head Name",
      flex: 1,
      valueGetter: (params) =>
        params.row.ExpenseDtl?.MExpenseHeads?.ExpenseHeadName,
    },
    {
      field: "FileName",
      headerName: "File Name",
      width: 500,
      renderCell: (params) => (
        <>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={(event) => handleFileClick(event, params.row)}
          >
            {params.row.FileName}
          </Link>
          {/* <a href={`${process.env.REACT_APP_BASE_URL}/${params.row?.FilePath}`} target="_blank" rel="noopener noreferrer">
            Open PDF in new tab
          </a> */}
        </>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
  ];

  const expenseHdrsColumns: GridColDef[] = [
    {
      field: "Project",
      headerName: "Project",
      width: 125,
      filterable: true,
      //flex: 1
    },
    {
      field: "ExpenseVoucherNo",
      headerName: "Voucher No",
      width: 125,
      filterable: true,
      //flex: 1
    },

    {
      field: "ExpenseVoucherDate",
      headerName: "Voucher Dt",
      width: 80,
      filterable: true,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YY") : "",
    },
    {
      field: "TotInvoiceAmt", headerName: "Tot Amount", width: 100, headerAlign: "right", filterable: true,
      cellClassName: "cell-right", valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "ExpenseDescription",
      headerName: "Expense Description",
      filterable: true,
      width: 200,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    {
      field: "ExpenseHdrStatus", headerName: "Status", width: 100, cellClassName: "cell-left", filterable: true,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              {params.value}
              {params.row.ExpenseHdrStatusID === 4 && (
                <Tooltip
                  title={
                    <>
                      {params.row.Level1RejectedRemark ?? ""}{" "}
                      {params.row.Level2RejectedRemark ?? ""}
                    </>
                  }
                >
                  <IconButton
                    size="small"
                    color="error"
                    aria-label="add an alarm"
                  >
                    <MessageIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </>
        );
      },
    },
    {
      field: "CreatedBy",
      headerName: "Created By",
      filterable: true,
      width: 100,
      valueGetter: (params) => params.row.CreatedBy,
    },
    {
      field: "ApproverName",
      headerName: "Approver",
      filterable: true,
      width: 125
    },

    {
      field: "CreatedOn",
      headerName: "Created On",
      filterable: true,
      width: 100,
      //flex: 1 ,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YY") : "",
    },
    {
      field: "ExpenseFor",
      headerName: "Expense For",
      filterable: true,
      width: 100,
    },
    {
      field: "VendorName",
      headerName: "Vendor Name",
      filterable: true,
      width: 100,
      flex: 1,
    },
    {
      field: "Details",
      headerName: "Details",
      width: 120,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => openExpenseDtlsDialog(params.row.ExpenseHdrId)}
            // onClick={() =>
            //   navigate("/expenseDtls/" + params.row.ExpenseHdrId, {
            //     state: {
            //       row: params.row,
            //     },
            //   })
            // }
            >
              Details ({params.row.TotDetails})
            </Button>

            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => openExpenseQueryDialog(params.row.ExpenseHdrId)}
            >
              Query ({params.row.QueriesTotDetails})
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "SendFor",
      headerName: "Send To",
      width: 90,
      //   flex: 1,
      renderCell: (params) => {
        return (
          params.row.ExpenseHdrStatusID === 0 &&
          params.row.TotDetails > 0 && (
            <Stack>
              <Button
                className="btnGrid"
                variant="contained"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure, want to send for Approval",
                    subTitle: "Once send, changes won't be allowed",
                    onConfirm: () => {
                      SendingForApproval(
                        params.row.ExpenseHdrId,
                        params.row.CreatedById
                      );
                    },
                  });
                }}
              // onClick={() => {
              //   handleApprovalInputOpen(params.row.ExpenseHdrId);
              //   getZoneApprovals();
              // }}
              >
                Next Level
              </Button>
            </Stack>
          )
        );
      },
    },

    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      width: 70,
      // flex: 1 ,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            {" "}
            {params.row.ExpenseHdrStatusID === 0 && (
              <IconButton
                size="small"
                color="primary"
                aria-label="add an alarm"
                onClick={() =>
                  navigate("/editExpense/" + params.row.ExpenseHdrId)
                }
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}

            <IconButton
              size="small"
              color="primary"
              aria-label="edit expense"
              onClick={() => {
                openExpenseDtlDocDialog(params.row.ExpenseHdrId);
              }}
            >
              <AttachFileIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
    // {
    //   field: "MarkAfterTallyTransfer",
    //   headerName: "TallyTransfered",
    //   width: 120,
    //   renderCell: (params) => {
    //     //   if (params.row.ExpenseHdrStatusID === 6)
    //     {
    //       return (
    //         <Button
    //           className="btnGrid"
    //           variant="contained"
    //           onClick={() => {
    //             setConfirmDialog({
    //               isOpen: true,
    //               title: "Are you sure, want to Transfer Tally",
    //               subTitle: "Once send, changes won't be allowed",
    //               onConfirm: () => {
    //                 TallyTransfered(params.row.ExpenseHdrId);
    //               },
    //             });
    //           }}
    //         >
    //          Mark After Tally Transfer
    //         </Button>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: "Level2ApprovalStatusDate",
    //   headerName: "Level2 Status Date",
    //   width: 120,
    //   flex: 1 ,
    //   valueFormatter: (params) =>
    //     params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    // },
  ];

  // // Function to handle filtering and calculate filtered rows
  // const handleFilterChange = (newFilterModel: any) => {
  //   setFilterModel(newFilterModel);
  //   // Filter rows manually based on filterModel
  //   let kk = [];
  //   const filtered = expenseHdrs.filter((row) => {
  //     return newFilterModel.items.every((filterItem: any) => {
  //       const field = filterItem.columnField;
  //       const value = filterItem.value?.toLowerCase() || "";
  //       
  //       let k = row[field].toString().toLowerCase().includes(value);
  //       alert(k);
  //       kk.push(k);
  //       return k;
  //     });
  //   });
  //   setExpenseHdrs(filtered);
  //   calculateTotalAmount(filtered);
  // };

  const calculateTotalAmount = (visibleRows: any) => {
    const total = visibleRows.reduce((sum: any, row: any) => sum + row.TotInvoiceAmt, 0);
    setTotalAmount(total);
  };

  return (
    <>
      <Typography variant="h5" align="center">
        Expenses
      </Typography>

      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <Controls.MuiAutocomplete
                options={sites}
                label="Site"
                error={errors.SiteId}
                onChange={(event: MultiSelectListModel) => {
                  handleInputChange({
                    target: {
                      name: "SiteId",
                      value: event ? event.value : "",
                    },
                  });

                  if (event) {
                    getProjectDirectory(event.value);
                  }
                  else {
                    setValues((prevValues: any) => ({
                      ...prevValues,
                      SiteId: '',
                      ProjectDirectoryId: ''
                    }));
                  }
                }}
                value={
                  sites.find((item: any) => item.value === values.SiteId) ||
                  null
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Controls.ReactDatePicker
                label="From Date"
                // min={values.FromDt}
                max={new Date()}
                value={values.FromDt = values.FromDt || get3MonthsAgoDate()}
                onChange={(date: any) => {
                  handleInputChange({
                    target: {
                      value: globalService.getISODateOnlyForDatePicker(date),
                      name: "FromDt",
                    },
                  });
                }}
                error={errors.FromDt}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Controls.ReactDatePicker
                label="To Date"
                min={values.FromDt}
                max={new Date()}
                value={values.ToDt = values.ToDt || new Date()}
                onChange={(date: any) => {
                  handleInputChange({
                    target: {
                      value: globalService.getISODateOnlyForDatePicker(date),
                      name: "ToDt",
                    },
                  });
                }}
                error={errors.ToDt}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button type="button" variant="contained" onClick={handleGo}>
                Go
              </Button>
              <Button
                type="button"
                sx={{ marginLeft: 1 }}
                startIcon={<RefreshIcon />}
                color="secondary"
                variant="contained"
                onClick={refresh}
              >
                Refresh
              </Button>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                onClick={() => {
                  getExpenseHdrs("0");
                }}
                style={{ marginRight: "10px" }}
              >
                Draft
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  getExpenseHdrs("2");
                }}
                style={{ marginRight: "10px" }}
              >
                Approved
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  getExpenseHdrs("4");
                }}
                style={{ marginRight: "10px" }}
              >
                Rejected
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  getExpenseHdrs("1");
                }}
                style={{ marginRight: "10px" }}
              >
                Pending with Reporting Manager
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  getExpenseHdrs("5");
                }}
                style={{ marginRight: "10px" }}
              >
                Pending with Expense Manager
              </Button>

              {globalService.roleMatch([ROLES.Accounts], auth) && <>
                <Button
                  variant="contained"
                  onClick={() => {
                    getExpenseHdrs("6");
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Marked For Tally
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    getExpenseHdrs("7");
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Transferred to  Tally
                </Button>
              </>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <>
        {visible && (
          <>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={() => navigate("/addExpense/")}
                    >
                      Add Record
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <strong>Total Amount: </strong> {globalService.formatToIndianCurrency(totalAmount)}
                  </Grid>
                </Grid>

                <div style={{ width: "100%" }}>
                  {globalService.roleMatch([ROLES.Accounts], auth) && (
                    <Grid item xs={12} sm={3}>
                      <Stack direction="row" spacing={1} marginY={1}>
                        {selectedExpenseHdrsIds.length > 0 && (
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    "Are you sure, want to send for Tally Transfer",
                                  subTitle:
                                    "Once send, changes won't be allowed",
                                  onConfirm: () => {
                                    if (selectedExpenseHdrsIds.length > 0) {
                                      UpdateForTally(selectedExpenseHdrsIds);
                                    } else {
                                      globalService.error(
                                        "No ExpenseHdrId selected."
                                      );
                                    }
                                  },
                                });
                              }}
                            >
                              Mark For Tally Transfer
                            </Button>
                          </Grid>
                        )}
                        <p>
                          {" "}
                          {selectedExpenseHdrsIds.length} Expenses selected.
                        </p>
                      </Stack>
                    </Grid>
                  )}
                  {/* {values.ExpenseHdrStatusID} */}
                  <DataGrid
                    getRowId={(row) => row.ExpenseHdrId}
                    rows={expenseHdrs}
                    columns={expenseHdrsColumns}
                    columnHeaderHeight={30}
                    //rowHeight={30}
                    autoHeight={true}
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    checkboxSelection
                    //filterModel={filterModel}
                    //onFilterModelChange={handleFilterChange}
                    //isRowSelectable={(params) => (params.row.expenseHdrStatusId === "2")}
                    isRowSelectable={(params) =>
                      params.row.ExpenseHdrStatusID === 2 && globalService.roleMatch([ROLES.Accounts], auth)
                    }
                    rowSelectionModel={selectedExpenseHdrsIds}
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={
                      handleConvertedmarkfortallySelectionChange
                    }
                    columnVisibilityModel={columnVisibilityModel} // dynamically controlled visibility
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)} // update visibility on change

                    //initialState={{
                    // columns: {
                    //   columnVisibilityModel: {
                    //     // Hide columns Id, the other columns will remain visible
                    //     ExpenseHdrId: false,
                    //     ApproverName: values.ExpenseHdrStatusID === 1 ? true : false,
                    //     //  ExpenseHdrStatus: globalService.roleMatch([ROLES.Accounts], auth) ? false : true,
                    //     Actions: globalService.roleMatch(
                    //       [ROLES.Accounts],
                    //       auth
                    //     )
                    //       ? false
                    //       : true,
                    //     MarkForTallyTransfer: globalService.roleMatch(
                    //       [ROLES.Accounts],
                    //       auth
                    //     )
                    //       ? true
                    //       : false,
                    //     Level1ApprovedByUser: globalService.roleMatch(
                    //       [ROLES.Accounts],
                    //       auth
                    //     )
                    //       ? false
                    //       : true,
                    //     Level1ApprovalStatusDate: globalService.roleMatch(
                    //       [ROLES.Accounts],
                    //       auth
                    //     )
                    //       ? false
                    //       : true,
                    //     CreatedById: globalService.roleMatch(
                    //       [ROLES.Accounts],
                    //       auth
                    //     )
                    //       ? false
                    //       : true,
                    //     Level2ApprovedByUser: globalService.roleMatch(
                    //       [ROLES.Accounts],
                    //       auth
                    //     )
                    //       ? false
                    //       : true,
                    //     Level2ApprovalStatusDate: globalService.roleMatch(
                    //       [ROLES.Accounts],
                    //       auth
                    //     )
                    //       ? false
                    //       : true,
                    //     SendFor: globalService.roleMatch(
                    //       [ROLES.Accounts],
                    //       auth
                    //     )
                    //       ? false
                    //       : true,
                    //     //ExpenseDescription: globalService.roleMatch([ROLES.Accounts], auth)? ((width:300): (width: 200))
                    //   },
                    // },
                    //   pagination: { paginationModel: { pageSize: 50 } },
                    // }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
              </CardContent>
            </Card>
            <Dialog
              fullWidth={true}
              maxWidth={maxWidth}
              open={expenseDtlDocOpen}
              onClose={closeExpenseDtlDocDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Expense Detail Documents
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={closeExpenseDtlDocDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
                <>
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.ExpenseDtlFileId}
                      rows={expenseDtlFiles}
                      columns={expenseDtlFileColumns}
                      columnHeaderHeight={30}
                      autoHeight={true}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                    />
                  </div>
                </>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeExpenseDtlDocDialog} variant="outlined">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={expenseQueryOpen}
          onClose={closeExpenseQueryDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Expense Queries</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeExpenseQueryDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
            <>
              <ExpenseQueriesList
                ExpenseHdrId={expenseHdrId}
                onCloseExpenseQueryDialog={closeExpenseQueryDialog}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeExpenseQueryDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={expenseDtlsOpen}
          onClose={closeExpenseDtlsDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Expense Details</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeExpenseDtlsDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
            <>
              <ExpenseDtlsList
                ExpenseHdrId={expenseHdrId}
                onCloseExpenseDtlsDialog={closeExpenseDtlsDialog}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeExpenseDtlsDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ExpenseHdrsList;
