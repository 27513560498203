import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
  IconButton,
  Link,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { ZoneApproversModel } from "../../models/ZoneApproversModel";
import { RentAgreementsService } from "../../services/RentAgreementsService";
import { useEffect, useRef, useState } from "react";
import {
  MultiSelectListModel,
  SelectListModel,
} from "../../models/ApiResponse";
import { mEmployeesService } from "../../services/MEmployeeService";
import { mZonesService } from "../../services/MZoneService";
import { RentAgreementsModel } from "../../models/RentAgreementsModel";
import { projectDirectoryService } from "../../services/ProjectDirectoryService";
import { ProjectDirectoryModel } from "../../models/ProjectDirectoryModel";
import { mSiteService } from "../../services/MSiteService";
import { mClientService } from "../../services/MClientService";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ROLES } from "../../utility/Config";
import dayjs from "dayjs";
import { rentAgreementFileService } from "../../services/RentAgreementFileService";
import ConfirmDialog from "../helper/ConfirmDialog";

const RentAgreementsForm = (...props: any) => {
  const { RentAgreementId }: any = useParams();
  const [packages, setPackages] = useState<any[]>([]);
  const [projectDirectory, setProjectDirectory] =
    useState<ProjectDirectoryModel>(null);
  let navigate = useNavigate();
  const [clients, setClients] = useState<MultiSelectListModel[]>([]);
  const [sites, setSites] = useState<SelectListModel[]>([]);
  const mode = RentAgreementId ? "Edit" : "Create";
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filesUpload, setFilesUpload] = useState<File[]>([]);
  const [rentAgreementFiles, setrentAgreementFiles] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });
  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("RentAgreementNo" in fieldValues)
      temp.RentAgreementNo = fieldValues.RentAgreementNo
        ? ""
        : "Rent Agreement No is required";

       if ("SiteId" in fieldValues)
      temp.SiteId = fieldValues.SiteId ? "" : "Please Select Site.";
    if ("RentAgreementStartOn" in fieldValues)
      temp.RentAgreementStartOn = fieldValues.RentAgreementStartOn
        ? ""
        : "Rent Agreement Start On is required";
    if ("RentAgreementEndOn" in fieldValues)
      temp.RentAgreementEndOn = fieldValues.RentAgreementEndOn
        ? ""
        : "Rent Agreement End On is required";
    if ("RentExpenseGenerationOn" in fieldValues)
      temp.RentExpenseGenerationOn = fieldValues.RentExpenseGenerationOn
        ? ""
        : "Rent Expense Generation On is required";
    // if ("RentPerMonth" in fieldValues)
    //   temp.RentPerMonth = fieldValues.RentPerMonth
    //     ? ""
    //     : "RentPer Month is required";

    
    if ("RentPerMonth" in fieldValues)
      temp.RentPerMonth = fieldValues.RentPerMonth
        ? (temp.RentPerMonth = /^[0-9\b]+$/.test(
          fieldValues.RentPerMonth.toString()
        )
          ? ""
          : "Rent Per Month is not valid.")
        : "Rent Per Month is required";
    if ("SecurityDeposit" in fieldValues)
      temp.SecurityDeposit = fieldValues.SecurityDeposit
        ? (temp.SecurityDeposit = /^[0-9\b]+$/.test(
          fieldValues.SecurityDeposit.toString()
        )
          ? ""
          : "Security Deposit is not valid.")
        : "Security Deposit is required";

    if ("ContactName" in fieldValues)
      temp.ContactName = fieldValues.ContactName
        ? ""
        : "Contact Name is required";
    if ("ContactMail" in fieldValues) {
      temp.ContactMail = fieldValues.ContactMail
        ? (temp.ContactMail =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            fieldValues.ContactMail
          )
            ? ""
            : "Please enter a valid email Id.")
        : "";
    }
    if ("ContactMobile" in fieldValues)
      temp.ContactMobile = fieldValues.ContactMobile
        ? ""
        : "Contact Mobile is required";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      RentAgreementsService.initialFieldValues,
      validate,
      RentAgreementId
    );

  const newZoneApprover = () => {
    setValues(RentAgreementsService.initialFieldValues);
  };

  function setFormValue(model: RentAgreementsModel) {
    let newModel = {
      RentAgreementId: model.RentAgreementId,
      ProjectDirectoryId: model.ProjectDirectoryId,
      RentAgreementNo: model.RentAgreementNo,
      RentAgreementStartOn: model.RentAgreementStartOn
        ? globalService.getISODateOnlyForDatePicker(
          new Date(model.RentAgreementStartOn)
        )
        : null,
      RentAgreementEndOn: model.RentAgreementEndOn
        ? globalService.getISODateOnlyForDatePicker(
          new Date(model.RentAgreementEndOn)
        )
        : null,
        RentExpenseGenerationOn: model.RentExpenseGenerationOn
        ? globalService.getISODateOnlyForDatePicker(
          new Date(model.RentExpenseGenerationOn)
        )
        : null,
      RentPerMonth: model.RentPerMonth,
      SecurityDeposit: model.SecurityDeposit,
      RefundAmount: model.RefundAmount,
      SecurityDepositRefundedOn: model.SecurityDepositRefundedOn
        ? globalService.getISODateOnlyForDatePicker(
          new Date(model.SecurityDepositRefundedOn)
        )
        : null,
      // ClientId: model.ProjectDirectory
      //   ? model.ProjectDirectory.ClientId
      //   : model.ClientId,
      SiteId: model.ProjectDirectory
        ? model.ProjectDirectory.SiteId
        : model.SiteId,
      ContactName: model.ContactName,
      ContactMail: model.ContactMail,
      ContactMobile: model.ContactMobile,
      Panno: model.Panno,
      BankName: model.BankName,
      Ifsccode: model.Ifsccode,
      AccountNo: model.AccountNo,
      Remark: model.Remark,
      // RentAgreementStatusId : model.
      RentAgreementStatus: model.RentAgreementStatus,
      // RentAgreementId: model.RentAgreementId,
      // ApproverUserId: model.ApproverUserId,
    };
    return newModel;
  }

  useEffect(() => {
    if (sites.length === 0) getSites();
    if (RentAgreementId) {
      getZoneApprover(RentAgreementId);
      getAllFileByRentAgreements();
      setErrors({});
    } else newZoneApprover();
  }, []);

  const getZoneApprover = (RentAgreementId: any) => {

    RentAgreementsService.getById(RentAgreementId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));

        if (result.list.ProjectDirectory) {

          getSites();
          setValues((prevValues: any) => ({
            ...prevValues,
            SiteId: result.list.ProjectDirectory.SiteId,
          }));
        }
        //  values.ExpenseHdrStatusId = result.list.ExpenseHdrStatusId;
      }
    });
  };

  // const getClients = () => {
  //   mClientService.GetAllMultiSelectList().then((response: any) => {
  //     setClients(response.data);
  //     setValues((prevValues: any) => ({
  //       ...prevValues,
  //       ClientId: "",
  //     }));
  //   });
  // };

  const getSites = () => {
    mSiteService.GetAllMultiSelectList().then((response: any) => {
      setSites(response.data);
    });
  };

  const getProjectDirectory = (siteId: any) => {
    if (siteId) {
      projectDirectoryService
        .GetBySiteId(siteId)
        .then((response) => {
          let result = response.data;
          setProjectDirectory(result.row);
          if (result.row)
            setValues((prevValues: any) => ({
              ...prevValues,
              ProjectDirectoryId: result.row.ProjectDirectoryId,
            }));
        });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (RentAgreementId) {
        RentAgreementsService.put(values, filesUpload).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate(-1);
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        RentAgreementsService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              navigate(-1);
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };

  const getAllFileByRentAgreements = () => {
    rentAgreementFileService.getAllFileByRentAgreements(RentAgreementId).then((response) => {
      setrentAgreementFiles(response.data.list);
    })
  }
  const removeFile = (index: number) => {
    setFilesUpload((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target.files;
    if (!fileInput) {
      return;
    }
    
    const selectedFiles = Array.from(fileInput) as File[];
    const validFiles = selectedFiles.filter(
      (file) => file.size <= 5 * 1024 * 1024
    );

    if (validFiles.length < selectedFiles.length) {
      globalService.error("File size must be 5MB or less");
    }
    setFilesUpload((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(`${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`, "_blank");
  };

  const rentAgreementFileColumns: GridColDef[] = [
    {
      field: "FileName",
      headerName: "File Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={(event) => handleFileClick(event, params.row)}
        >
          {params.row.FileName}
        </Link>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this ExpenseFile ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeRentAgreementFile(params.row.RentAgreementFileId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const removeRentAgreementFile = (RentAgreementFileId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    rentAgreementFileService.remove(RentAgreementFileId).then((response) => {
      if (response) {
        getAllFileByRentAgreements();
      }
    });
  };

  return (
    <>
      <Typography variant="h5" align="center">
        Rent Agreements
      </Typography>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Controls.MuiAutocomplete
                  options={sites}
                  label="Site"
                  error={errors.SiteId}
                  onChange={(event: MultiSelectListModel) => {
                    handleInputChange({
                      target: {
                        name: "SiteId",
                        value: event ? event.value : "",
                      },
                    });

                    if (event) {
                      getProjectDirectory(event.value);
                    }
                  }}
                  value={
                    sites.find((item: any) => item.value === values.SiteId) ||
                    null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="Rent Agreement No"
                  name="RentAgreementNo"
                  value={values.RentAgreementNo}
                  onChange={handleInputChange}
                  error={errors.RentAgreementNo}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.ReactDatePicker
                  label="Rent Agreement Start On"
                  // maxDate={EighteenYearsAgoDate}
                  value={values.RentAgreementStartOn}
                  onChange={(date: any) => {
                    handleInputChange({
                      target: {
                        value: globalService.getISODateOnlyForDatePicker(date),
                        name: "RentAgreementStartOn",
                      },
                    });
                  }}
                  error={errors.RentAgreementStartOn}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.ReactDatePicker
                  label="Rent Agreement End On"
                  // maxDate={EighteenYearsAgoDate}
                  value={values.RentAgreementEndOn}
                  onChange={(date: any) => {
                    handleInputChange({
                      target: {
                        value: globalService.getISODateOnlyForDatePicker(date),
                        name: "RentAgreementEndOn",
                      },
                    });
                  }}
                  error={errors.RentAgreementEndOn}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.ReactDatePicker
                  label="Rent Expense Generation On"
                  // maxDate={EighteenYearsAgoDate}
                  value={values.RentExpenseGenerationOn}
                  onChange={(date: any) => {
                    handleInputChange({
                      target: {
                        value: globalService.getISODateOnlyForDatePicker(date),
                        name: "RentExpenseGenerationOn",
                      },
                    });
                  }}
                  error={errors.RentExpenseGenerationOn}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="Rent Per Month"
                  name="RentPerMonth"
                  type="Number"
                  value={values.RentPerMonth}
                  onChange={handleInputChange}
                  error={errors.RentPerMonth}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="Security Deposit"
                  name="SecurityDeposit"
                  type="Number"
                  value={values.SecurityDeposit}
                  onChange={handleInputChange}
                  error={errors.SecurityDeposit}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                className={mode !== "Edit" ? "hidden" : ""}
              >
                <Controls.Input
                  label="Refund Amount"
                  name="RefundAmount"
                  type="Number"
                  value={values.RefundAmount}
                  onChange={handleInputChange}
                  error={errors.RefundAmount}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className={mode !== "Edit" ? "hidden" : ""}
              >
                <Controls.ReactDatePicker
                  label="Security Deposit Refunded On"
                  // maxDate={EighteenYearsAgoDate}
                  value={values.SecurityDepositRefundedOn}
                  onChange={(date: any) => {
                    handleInputChange({
                      target: {
                        value: globalService.getISODateOnlyForDatePicker(date),
                        name: "SecurityDepositRefundedOn",
                      },
                    });
                  }}
                  error={errors.SecurityDepositRefundedOn}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="Contact Name"
                  name="ContactName"
                  value={values.ContactName}
                  onChange={handleInputChange}
                  error={errors.ContactName}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="Contact Mail"
                  name="ContactMail"
                  value={values.ContactMail}
                  onChange={handleInputChange}
                  error={errors.ContactMail}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="Contact Mobile"
                  name="ContactMobile"
                  value={values.ContactMobile}
                  onChange={handleInputChange}
                  error={errors.ContactMobile}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="PAN No"
                  name="Panno"
                  value={values.Panno}
                  onChange={handleInputChange}
                  error={errors.Panno}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="Bank Name"
                  name="BankName"
                  value={values.BankName}
                  onChange={handleInputChange}
                  error={errors.BankName}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="IFSC Code"
                  name="Ifsccode"
                  value={values.Ifsccode}
                  onChange={handleInputChange}
                  error={errors.Ifsccode}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="Account No"
                  name="AccountNo"
                  value={values.AccountNo}
                  onChange={handleInputChange}
                  error={errors.AccountNo}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controls.Input
                  label="Remark"
                  name="Remark"
                  value={values.Remark}
                  onChange={handleInputChange}
                  error={errors.Remark}
                />
              </Grid>
              {RentAgreementId && 
              <Grid item xs={12} sm={4}>
                  <Typography style={{ color: "red", fontSize: "0.75rem" }}>
                    {" "}
                    File Size Should be 5MB
                  </Typography>
                  <Stack spacing={1} direction="row">
                    <Grid item xs={12}>
                      <input
                        type="file"
                        name="file"
                        accept="*/*"
                        multiple
                        onChange={onFileChange}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        id="file-input"
                      />
                      <Button
                        startIcon={<PostAddIcon />}
                        variant="contained"
                        //color="success"
                        sx={{ backgroundColor: "success.main", opacity: 0.8 }}
                        onClick={() => fileInputRef.current?.click()}
                      >
                        Browse File
                      </Button>
                    </Grid>
                    {filesUpload.length > 0 && (
                      <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                          {filesUpload.map((file, index) => (
                            <Stack
                              direction="row"
                              spacing={1}
                              key={index}
                              alignItems="center"
                            >
                              <Typography>{file.name}</Typography>
                              <IconButton
                                size="small"
                                aria-label="delete"
                                color="error"
                                onClick={() => removeFile(index)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Stack>
                          ))}
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setFilesUpload([])}
                          >
                            Remove All
                          </Button>
                        </Stack>
                      </Grid>
                    )}
                  </Stack>
              </Grid>
              }
            </Grid>
            {RentAgreementId && 
              <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.RentAgreementFileId}
                      rows={rentAgreementFiles}
                      columns={rentAgreementFileColumns}
                      columnHeaderHeight={30}
                      autoHeight={true}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                    />
                  </div>
                </div>
              }
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
export default RentAgreementsForm