import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  Link,
  Divider,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  CardActions,
  DialogActions,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ExpenseHdrsService } from "../../services/ExpenseHdrsService";
import dayjs from "dayjs";
import Controls from "../../utility/Controls.tsx/Controls";
import {
  AuthContext,
  initialFieldValues,
} from "../../utility/context/AuthContext";
import useForm from "../../utility/hooks/UseForm";
import { ExpenseApprovalService } from "../../services/ExpenseApprovalService";
import { globalService } from "../../services/GlobalService";
import { ExpenseHdrsModel } from "../../models/ExpenseHdrsModel";
import ExpenseQueriesList from "./ExpenseQueriesList";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { expenseDtlFileService } from "../../services/ExpenseDtlFileService";
import { config } from "../../utility/Config";
import { ExpenseQueriesModel } from "../../models/ExpenseQueriesModel";
import { Guid } from "guid-typescript";
import ExpenseDtlsList from "./ExpenseDtlsList";


const ExpenseApprovalList: React.FC = (...props: any) => {
  const [expenseApproval, setExpenseApproval] = useState([]);
  const [approvalHistorys, setapprovalHistory] = useState([]);
  const [expenseManager, setExpenseManager] = useState([]);
  const [openRejectD, setopenRejectD] = useState(false);
  const { auth } = React.useContext(AuthContext);
  const [expenseDtlsOpen, setExpenseDtlsOpen] = React.useState(false);
  const [openExpenseManagerD, setopenExpenseManagerD] = useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const { UserId }: any = useParams();
  const { ExpenseHdrId } = useParams();
  const [value, setValue] = React.useState("1");
  const [expenseHdrId, setExpenseHdrId] = useState<any>(null);
  const [expenseQueryOpen, setExpenseQueryOpen] = React.useState(false);
  const [expenseDtlDocOpen, setExpenseDtlDocOpen] = React.useState(false);
  const [expenseDtlFiles, setExpenseDtlFiles] = useState([]);
  const navigate = useNavigate();

  const openExpenseDtlDocDialog = (expenseHdrId: any) => {
    setExpenseDtlDocOpen(true);
    getExpenseDtlFilesByHeaderId(expenseHdrId);
  };

  const closeExpenseDtlDocDialog = () => {
    setExpenseDtlDocOpen(false);
  };

  const openExpenseQueryDialog = (expenseHdrId: any) => {
    setExpenseHdrId(expenseHdrId);
    setExpenseQueryOpen(true);
  };
  const closeExpenseQueryDialog = () => {
    setExpenseQueryOpen(false);
  };

  const ExpenseHdrStatus = [
    { value: 0, Text: "Draft" },
    { value: 1, Text: "Submitted for Approval 1" },
    { value: 2, Text: "Approved" },
    { value: 3, Text: "Lapsed" },
    { value: 4, Text: "Rejected" },
    { value: 5, Text: "Submitted for Approval 2" },
    { value: 6, Text: "Approved by Accounts" },
  ];
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });

  const handleRejectopen = (ExpenseHdrId: any) => {
    values.ExpenseHdrId = ExpenseHdrId;
    setopenRejectD(true);
  };

  const handleRejectclosed = () => {
    setopenRejectD(false);
  };
  const openExpenseDtlsDialog = (expenseHdrId: any) => {
    setExpenseHdrId(expenseHdrId);
    setExpenseDtlsOpen(true);
  };
  const closeExpenseDtlsDialog = () => {
    setExpenseDtlsOpen(false);
  };
  const handleExpenseManageopen = (row: any) => {
    values.ExpenseHdrId = row;
    setopenExpenseManagerD(true);
  };
  const handleExpenseManageclose = () => {
    setopenExpenseManagerD(false);
  };
  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    if ("Status" in fieldValues)
      temp.Status = fieldValues.Status ? "" : "Please Select Status";
    if ("UserId" in fieldValues)
      temp.UserId = fieldValues.UserId ? "" : "Please Select The User Manager";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    if (UserId) getExpenseApproval();
  }, []);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      ExpenseHdrsService.initialFieldValues,
      validate,
      props.setCurrentId
    );

  const getExpenseDtlFilesByHeaderId = (ExpenseHeaderId: any) => {
    expenseDtlFileService
      .getAllFileByExpenseHeaderId(ExpenseHeaderId)
      .then((response) => {
        let result = response.data;
        setExpenseDtlFiles(result.list);
      });
  };

  const getExpenseApproval = () => {
    ExpenseApprovalService.getExpensesForApproval(UserId).then((response) => {
      let result = response.data;
      setExpenseApproval(result.list);
    });
  };

  const SendingForApproval = (ExpenseHdrId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const updatedValues = {
      ...values,
      ExpenseHdrId,
      ExpenseHdrStatusId: 2,
    };

    values.ExpenseHdrID = ExpenseHdrId;

    ExpenseHdrsService.updateLevel2Approve(updatedValues).then(
      (response: any) => {
        let result = response.data;
        if (response) {
          globalService.success(result.message);
          getExpenseApproval();
          navigate("/expenseApproval/" + UserId);
        } else {
          globalService.error(result.message);
        }
      }
    );
  };

  const SendingForDraft = (expenseHeaderId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    let model: ExpenseQueriesModel = {
      ExpenseQueryId: Guid.EMPTY,
      ExpenseHdrId: expenseHeaderId,
      //ExpenseHdrStatusId: 0,
      Remark: values.Level1RejectedRemark,
    };
    ExpenseHdrsService.UpdateForDraft(model).then(
      (response: any) => {
        let result = response.data;
        if (response) {
          handleRejectclosed();
          globalService.success(result.message);
          getExpenseApproval();
          navigate("/expenseApproval/" + UserId);
        } else {
          globalService.error(result.message);
        }
      }
    );
  };

  const SendingForRejection = (expenseHeaderId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    const updatedValues = {
      ...values,
      ExpenseHdrId: expenseHeaderId,
      ExpenseHdrStatusId: 4,
      Level1RejectedRemark: values.Level1RejectedRemark,
    };
    ExpenseHdrsService.UpdateLevel1Reject(updatedValues).then(
      (response: any) => {
        let result = response.data;
        if (response) {
          handleRejectclosed();
          globalService.success(result.message);
          getExpenseApproval();
          navigate("/expenseApproval/" + UserId);
        } else {
          globalService.error(result.message);
        }
      }
    );
  };

  const SendingToExpenseManager = (ExpenseHdrId: any) => {

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const updatedValues = {
      ...values,
      ExpenseHdrId,
      ExpenseHdrStatusId: 5,
      //Level2ApprovedByUserId: values.UserId,
    };
    ExpenseHdrsService.UpdateExpenseHdrStatusByApprover2(updatedValues).then(
      (response: any) => {
        let result = response.data;
        if (response) {
          handleExpenseManageclose();
          globalService.success(result.message);
          getExpenseApproval();
          navigate("/expenseApproval/" + UserId);
        } else {
          handleExpenseManageclose();
          globalService.error(result.message);
        }
      }
    );
  };

  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 3);
    return oneMonthsAgo;
  }
  const handleConfirmationReject = (expenseHeaderId: any) => {
    if (!values.Level1RejectedRemark) {
      return globalService.error('Rejection Remark is required.');
    }
    values.ExpenseHdrId = expenseHeaderId;
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to send for Reject?",
      subTitle: "Once send, changes won't be allowed",
      onConfirm: () => {
        SendingForRejection(expenseHeaderId);
      },
    });
  };

  const handleProceedForDraft = (expenseHeaderId: any) => {
    if (!values.Level1RejectedRemark) {
      return globalService.error('Remark / Query is required.');
    }

    values.ExpenseHdrId = expenseHeaderId;
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to send for Draft?",
      subTitle: "",
      onConfirm: () => {
        SendingForDraft(expenseHeaderId);
      },
    });
  }


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    values.UserId = auth.Id;
    ExpenseApprovalService.getExpensesForApprovalHistory(values).then(
      (response: any) => {
        let result = response.data;
        setapprovalHistory(result.list.Result);

      }
    );
  };
  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(`${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`, "_blank");
  };

  const itemcoloumnsapprovalHistorys: GridColDef[] = [
    {
      field: "Status",
      headerName: "Action",
      width: 200,
      //flex: 1,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            <IconButton
              size="small"
              color="primary"
              aria-label="edit expense"
              onClick={
                () => {
                  openExpenseDtlDocDialog(params.row.ExpenseHdrID);
                }
              }
            >
              <AttachFileIcon fontSize="inherit" />
            </IconButton>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => openExpenseDtlsDialog(params.row.ExpenseHdrID)}
            // onClick={() =>
            //   navigate("/expenseDtls/" + params.row.ExpenseHdrId, {
            //     state: {
            //       row: params.row,
            //     },
            //   })
            // }
            >
              Details ({params.row.TotDetails})
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={
                () => openExpenseQueryDialog(params.row.ExpenseHdrID)
              }
            >
              Query ({params.row.QueriesTotDetails})
            </Button>

          </Stack>
        );
      },
    },
    { field: "Project", headerName: "Project", flex: 1, },
    { field: "ExpenseVoucherNo", headerName: "Voucher No", width: 130 },
    {
      field: "ExpenseVoucherDate", headerName: "Voucher Date", width: 100,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "TotInvoiceAmt", headerName: "Total Amount", width: 130,
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "ExpenseDescription", cellClassName: "cell-left", headerName: "Expense Description", flex: 1,
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },

    { field: "ExpenseHdrStatus", headerName: "Expense Hdr Status", flex: 1 },
    {
      field: "ExpenseFor",
      headerName: "Expense For",
      width: 100,
    },
    {
      field: "VendorName",
      headerName: "Vendor Name",
      width: 100,
    },
    //{ field: "Level1ApprovalStatusDate", headerName: "Level1 Approval Status Date", flex: 1, },
    { field: "UserName", headerName: "User Name", flex: 1 },
    { field: "LevelApproval", headerName: "Level Approval", flex: 1 },
  ];
  // const handleConfirmationExpenseManager = (row: any) => {
  //   setConfirmDialog({
  //     isOpen: true,
  //     title: "Are you sure, want to send to Expense Manager",
  //     subTitle: "Once send, changes won't be allowed",
  //     onConfirm: () => {
  //       SendingToExpenseManager();
  //     },
  //   });
  // };

  // const getExpenseManagerList = () => {
  //   ExpenseHdrsService.GetExpenseManagerList().then((response: any) => {
  //     setExpenseManager(response.data);
  //   });
  // };
  const expenseApprovalColumns: GridColDef[] = [
    {
      field: "Status",
      headerName: "Action",
      width: 400,
      //flex: 1,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            {/* <Stack spacing={2} direction="row" marginTop={1}> */}
            <IconButton
              size="small"
              color="primary"
              aria-label="edit expense"
              onClick={
                () => {
                  openExpenseDtlDocDialog(params.row.ExpenseHdrId);
                }
              }
            >
              <AttachFileIcon fontSize="inherit" />
            </IconButton>
            <Button
              className="btnGrid"
              variant="contained"
              // onClick={() =>
              //   navigate("/expenseDtls/" + params.row.ExpenseHdrId, {
              //     state: {
              //       row: params.row,
              //     },
              //   })
              // }
              onClick={() => openExpenseDtlsDialog(params.row.ExpenseHdrId)}
            >
              Details ({params.row.TotDetails})
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={
                () => openExpenseQueryDialog(params.row.ExpenseHdrId)
                // navigate("/expenseQueries/" + params.row.ExpenseHdrId, {
                //   state: {
                //     row: params.row,
                //   },
                // })
              }
            >
              Query ({params.row.QueriesTotDetails})
            </Button>
            {/* </Stack> */}
            {/* <Stack spacing={2} direction="row" marginTop={1}> */}
            {params.row.ExpenseHdrStatusID === 5 && (
              <Button
                className="btnGrid"
                variant="contained"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure, want to Approve",
                    subTitle: "Once send, changes won't be allowed",
                    onConfirm: () => {
                      SendingForApproval(params.row.ExpenseHdrId);
                    },
                  });
                }}
              >
                Approve
              </Button>
            )}
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => {
                handleRejectopen(params.row.ExpenseHdrId);
              }}
            >
              Reject / Send Back to Draft Mode
            </Button>
            {/* </Stack> */}
            {/* <Stack spacing={2} direction="row" marginTop={1}> */}
            {params.row.ExpenseHdrStatusID === 1 && (
              <Button
                className="btnGrid"
                variant="contained"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure, want to send to Expense Manager",
                    subTitle: "Once send, changes won't be allowed",
                    onConfirm: () => {
                      SendingToExpenseManager(params.row.ExpenseHdrId);
                    },
                  });
                }}
              // onClick={() => {
              //   handleExpenseManageopen(params.row.ExpenseHdrId);
              //   getExpenseManagerList();
              // }}
              >
                Send To Expense Manager
              </Button>
            )}
            {/* </Stack> */}
          </Stack>
        );
      },
    },
    {
      field: "Project",
      headerName: "Project",
      // width: 130,
      flex: 1,

    },
    {
      field: "ExpenseVoucherNo",
      headerName: "Voucher No",
      //width: 130,
      flex: 1,

    },
    {
      field: "ExpenseVoucherDate",
      headerName: "Voucher Date",
      width: 130,

      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "TotInvoiceAmt",
      headerName: "Total Amount",
      width: 130,
      align: "right",
      headerAlign: "right",
      cellClassName: "cell-right", valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },

    },
    {
      field: "UserName",
      headerName: "Created By",
      // width: 130,
      flex: 1,

    },

    // { field: "ExpenseHdrStatusID", headerName: "ExpenseHdrStatusID", width: 130,  align: "left" },
    //  { field: "ExpenseHdrStatus", headerName: "Status", width: 130,  align: "left" },
    //  { field: "Level1ApprovedByUserID", headerName: "Level1 ApprovedBy", width: 130,  align: "left" },
    // {
    //   field: "Level1ApprovalStatusDate",
    //   headerName: "Level1 Status Date",
    //   width: 130,
    //   
    //   valueFormatter: (params) =>
    //     params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    // },
    // {
    //   field: "Level1RejectedRemark",
    //   headerName: "Level1 Rejected Remark",
    //   width: 130,
    //   
    // },
    // {
    //   field: "Level2ApprovedByUserID",
    //   headerName: "Level2 ApprovedBy",
    //   width: 130,
    //   
    // },
    // {
    //   field: "Level2ApprovalStatusDate",
    //   headerName: "Level2 Status Date",
    //   width: 130,
    //   
    //   valueFormatter: (params) =>
    //     params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    // },
    // {
    //   field: "Level2RejectedRemark",
    //   headerName: "Level2 Rejected Remark",
    //   width: 130,
    //   
    // },
    // {
    //   field: "CreatedByID",
    //   headerName: "Created By",
    //   width: 130,
    //   
    //   valueGetter: (params) => params.row.CreatedBy?.UserName,
    // },
    {
      field: "CreatedOn",
      headerName: "Created On",
      width: 100,

      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "ExpenseFor",
      headerName: "Expense For",
      width: 100,
    },
    {
      field: "VendorName",
      headerName: "Vendor Name",
      width: 100,
    },
    {
      field: "ExpenseHdrStatus",
      headerName: "Status",
      // width: 120,
      flex: 1,

    },
  ];

  const expenseDtlFileColumns: GridColDef[] = [
    {
      field: "ExpenseHeadName",
      headerName: "Expense Head Name",
      flex: 1,
      valueGetter: (params) => params.row.ExpenseDtl?.MExpenseHeads?.ExpenseHeadName,
    },
    {
      field: "FileName",
      headerName: "File Name",
      width: 500,
      renderCell: (params) => (
        <>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={(event) => handleFileClick(event, params.row)}
          >
            {params.row.FileName}
          </Link>

        </>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },

  ];

  return (
    <>
      <Typography variant="h5" align="center">
        Expense Approval
      </Typography>


      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="To be Approved" value="1" />
            <Tab label="History" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <Card>
            <CardContent>
              <div style={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.ExpenseHdrId}
                  rows={expenseApproval}
                  columns={expenseApprovalColumns}
                  columnHeaderHeight={30}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        ExpenseHdrID: false,
                      },
                    },

                    pagination: { paginationModel: { pageSize: 50 } },
                  }}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,

                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
              </div>
            </CardContent>
          </Card>
        </TabPanel>

        <TabPanel value="2">
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <React.Fragment>
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={2}>
                      <Controls.ReactDatePicker
                        label="From Date"
                        // min={values.FromDt}
                        value={
                          (values.FromDt = values.FromDt || getDefaultDate())
                        }
                        max={new Date()}
                        //value={values.FromDt}
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value: globalService.getISODateOnlyForDatePicker(date),
                              name: "FromDt",
                            },
                          });
                        }}
                        error={errors.FromDt}
                      />
                    </Grid>

                    <Grid item xs={5} sm={2}>
                      <Controls.ReactDatePicker
                        label="To Date"
                        min={values.FromDt}
                        max={new Date()}
                        value={(values.ToDt = values.ToDt || new Date())}
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value: globalService.getISODateOnlyForDatePicker(date),
                              name: "ToDt",
                            },
                          });
                        }}
                        error={errors.ToDt}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <Button type="submit" variant="contained">
                        Go
                      </Button>
                    </Grid>
                  </Grid>

                  <CardContent>
                    <div style={{ width: "100%" }}>
                      <DataGrid
                        getRowId={(row) => row.ExpenseHdrID}
                        rows={approvalHistorys}
                        columns={itemcoloumnsapprovalHistorys}
                        columnHeaderHeight={30}
                        autoHeight={true}
                        getRowHeight={() => "auto"}
                        getEstimatedRowHeight={() => 200}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              ExpenseHdrID: false,
                            },
                          },

                          pagination: { paginationModel: { pageSize: 50 } },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,

                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                    </div>
                  </CardContent>
                </React.Fragment>
              </CardContent>
            </Card>
          </form>
        </TabPanel>
      </TabContext>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          onClose={handleRejectclosed}
          aria-labelledby="customized-dialog-title"
          open={openRejectD}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, textAlign: "center" }}
            id="customized-dialog-title"
          >
            Reject Remark / Query
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleRejectclosed}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme: any) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <form
              autoComplete="off"
              noValidate
              onSubmit={SendingToExpenseManager}
            >
              <Card>
                <CardContent>
                  <React.Fragment>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Controls.Input
                          multiline
                          rows={4}
                          label="Remark / Query"
                          name="Level1RejectedRemark"
                          value={values.Level1RejectedRemark}
                          onChange={handleInputChange}
                          error={errors.Level1RejectedRemark}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                </CardContent>
                <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      //className="btnGrid"
                      variant="contained"
                      onClick={() =>
                        handleProceedForDraft(values.ExpenseHdrId)
                      }
                    >
                      Send Back to Draft Mode
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() =>
                        handleConfirmationReject(values.ExpenseHdrId)
                      }
                    >
                      Reject
                    </Button>
                  </Stack>
                </CardActions>
              </Card>
            </form>
          </DialogContent>
        </Dialog>
      </>
      <>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          onClose={handleExpenseManageclose}
          aria-labelledby="customized-dialog-title"
          open={openExpenseManagerD}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, textAlign: "center" }}
            id="customized-dialog-title"
          >
            Send to Expense Manager
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleExpenseManageclose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme: any) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <form autoComplete="off" noValidate onSubmit={SendingForRejection}>
              <Card>
                {/* <CardContent>
                  <React.Fragment>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Controls.Select
                          label="Manager Name"
                          name="UserId"
                          required
                          options={expenseManager}
                          value={expenseManager.length > 0 ? values.UserId : ""}
                          onChange={handleInputChange}
                          error={errors.UserId}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                </CardContent> */}
                <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      //className="btnGrid"
                      variant="contained"
                    // onClick={() =>
                    //   handleConfirmationExpenseManager(values.UserId)
                    // }
                    >
                      submit
                    </Button>
                    {/* <Button type="submit" variant="contained" onClick={() => handleConfirmation(params.row)}>
          Send for Approval
        </Button> */}
                  </Stack>
                </CardActions>
              </Card>
            </form>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={expenseQueryOpen}
          onClose={closeExpenseQueryDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Expense Queries</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeExpenseQueryDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400 }}>
            <>
              <ExpenseQueriesList
                ExpenseHdrId={expenseHdrId}
                onCloseExpenseQueryDialog={closeExpenseQueryDialog}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeExpenseQueryDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={expenseDtlDocOpen}
          onClose={closeExpenseDtlDocDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Expense Detail Documents
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeExpenseDtlDocDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
            <>

              <div style={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.ExpenseDtlFileId}
                  rows={expenseDtlFiles}
                  columns={expenseDtlFileColumns}
                  columnHeaderHeight={30}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                />
              </div>

            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeExpenseDtlDocDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={expenseDtlsOpen}
          onClose={closeExpenseDtlsDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Expense Details</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeExpenseDtlsDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
            <>
              <ExpenseDtlsList
                ExpenseHdrId={expenseHdrId}
                onCloseExpenseDtlsDialog={closeExpenseDtlsDialog}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeExpenseDtlsDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default ExpenseApprovalList;
